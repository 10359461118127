.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
    margin: 0;
    height: 100%;
    background-color: #323232;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
}
#title {
    font-family: 'Roboto Slab', serif;
    font-size: 4em;
}
.full {
    margin: 0;
    height: 100%;
    width: 100%;
}
.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.linkbar_item {
    float: left;
    margin: 1em;
}
.linkbar_link {
    color: black;
    text-decoration: none;
    color: #FFFFFF;
}
a.linkbar_link:hover {
    color: gray;
}
.linkbar_divider {
    float: left;
    margin: 1em 0 1em 0;

    cursor: default;
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
}
.title_underline {
    border-top: 5px dotted;
}
